import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/orders?page=" + page);
    }
    getCompanyType() {
        return axios.get('/companyType')
    }
    get(id) {
        return axios.get(`/orders/${id}`);
    }
    workshops() {
        return axios.get(`/lkbWorkShop`,);
    }
    changeOrderWorkshop(data) {
        return axios.post("/changeOrderWorkshop", data);
    }


    delete(id) {
        return axios.delete(`/orders/${id}`);
    }
    deleteAll() {
        return axios.delete(`/orders`);
    }
    findByTitle(title) {
        return axios.get(`/orders?title=${title}`);
    }
    changeStatus(id) {
        return axios.get(`/changeCustomersStatus/${id}`)
    }
}
export default new DataService();