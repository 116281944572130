<template>

<b-card>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive" :companyType="companyType" v-on:getData="getData"   :dataSlider="sidebarData"  
    />

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
     <!-- <b-button
        @click="addNewData"
        variant="outline-primary"
        class="mb-2"
      >
        Add Company
      </b-button> -->
      

      <b-table responsive :items="items.data" :fields="tableColumns" hover @row-clicked="showDetails">

      <template #cell(id)="data">
          {{ clacItemsId(data.index) }}
      </template>


      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

      <template #cell(brand_image)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(customers_image)="data">
        <b-avatar :src="data.value" />
      </template>


      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.index)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(created_by)="data">
        {{data.value.name}} 
      </template>

      <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'order-details', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            
            <b-dropdown-item @click="editData(data.item)"  :disabled='disableChageWorkshop(data.item.status)'>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Change WorkShop</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="deleteData(data.item.id)" > 
              <feather-icon icon="TrashIcon" />
              <span  class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>


          
        </template>
      </b-table>
    
      <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          
          :total-rows="items.total"
          :per-page="items.per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value)=>getData(value)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>


    </b-overlay>
  </b-card>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton, BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'

export default {
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    ToastificationContent,
    BPagination,
  },

  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      companyType: [],
      currentPage: 1,
      perPage: 1,
      rows: 200,
      tableColumns: [
            // { key: 'show_details', label: 'Details'},
            { key: 'id', sortable: true },
            { key: 'customer_name', label: 'Customer', sortable: true },
            { key: 'work_shop.name', label: 'WorkShop', sortable: true},
            { key: 'payment_method.name', label: 'Payment Methods', sortable: true },
            // { key: 'year', label: 'Year', sortable: true },
            { key: 'total_amount', label: 'Total Amount', sortable: true },
            { key: 'status', label: 'Status', sortable: true },
            { key: 'created_at', label: 'Created At', sortable: true },
            { key: 'address', label: 'Address' },
            { key: 'actions', label: 'Actions' },
      ],
      status: [{
        completed: 'completed', Confirmed: 'Confirmed', canceled: 'canceled', pending: 'pending', arrived: 'arrived',
      },
      {
        completed: 'light-primary', Confirmed: 'light-success', canceled: 'light-danger', pending: 'light-warning', arrived: 'light-info',
      }],
      
    }
  },
  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAll(page)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        // this.currentPage = response.data.data.current_page
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    deleteData(id){
      this.isLoading = true;
      DataService.delete(id)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    changeStatus(id, index){
      this.isLoading = true;
      DataService.changeStatus(id)
      .then(response => {
        if(this.items.data[index].status == 1){
          this.items.data[index].status = 0
        }else{
          this.items.data[index].status = 1
        }
        
        this.isLoading = false
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      
        
      })
    },
    disableChageWorkshop(val) {
      if (val == 'canceled' || val == 'completed'){
        return true;
      }
      return false
    },

    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(newData){
      this.items = newData
    },
    clacItemsId(index){
      return this.items.from + index
    },
    showDetails(row){
      this.$router.push(`/order-details/${row.id}`)
    },
  },
  mounted() {
    this.getData(this.currentPage)
  },
  
}
</script>